<template>
  <keep-alive>
    <v-container
      fluid
      class="d-flex flex-column h-100 ma-0 pa-0 min-height-inherit align-center"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        class="d-flex flex-row min-height-inherit h-100 w-100 pa-0 justify-center"
      >
        <agency-left-section class="hidden-md-and-down" />
        <router-view />
      </div>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import AgencyLeftSection from "../AgencyLeftSection.vue";
export default {
  components: { AgencyLeftSection },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
    };
  },
  methods: {
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
  },
  mounted() {
    this.notifications = this.$route.params.notifications;
    this.errorNotifications = this.$route.params.error;
  },
};
</script>
<style scoped></style>
